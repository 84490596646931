import { Controller } from "@hotwired/stimulus"
import { get, post, patch } from "@rails/request.js"
import { DirectUpload } from "@rails/activestorage"
import WebViewer from "@pdftron/webviewer"

const LEAVING_PAGE_MESSAGE = "You have unsaved changes to this form, if you leave now those changes will be lost. You must press the 'Save PDF & Continue' button in order to save your changes. Are you sure you want to leave this page?"

export default class extends Controller {
  static targets = ["viewer", "saveBtn", "form"]

  connect() {
    WebViewer({
      path: "/webviewer",
      preloadWorker: "pdf",
      isAdminUser: true,
      annotationUser: this.data.get("user"),
      fullAPI: false,
      licenseKey: "Practical American Safety Solutions, Inc:OEM:PASS Tools::B+:AMS(20250312):9FB57F9D0437A60A8360B03AC9A2537860611FC56E141A47F5345B8A9D6430A6329431F5C7"
    }, this.viewerTarget)
      .then(instance => {
        const { Core, UI } = instance
        const { documentViewer, annotationManager, Annotations } = Core

        const templateUrl = this.data.get("templateUrl")
        const documentId = this.data.get("documentId")

        documentViewer.loadDocument(templateUrl, { docId: documentId, extension: "pdf" })

        this.documentViewer = documentViewer

        this.documentId = this.data.get("documentId")

        this.annotationManager = annotationManager
        this.fieldManager = annotationManager.getFieldManager()

        UI.disableElements(["toolbarGroup-Shapes"])
        UI.disableElements(["toolbarGroup-Edit"])
        UI.disableElements(["toolbarGroup-Insert"])
        UI.disableElements(["toolbarGroup-Forms"])
        UI.disableElements(["toolbarGroup-FillAndSign"])
        UI.disableElements(["pageNavOverlay"])

        UI.disableFeatures(UI.Feature.Download)
        UI.disableFeatures(UI.Feature.Print)

        UI.setFitMode("FitWidth")

        const customStyles = function (widget) {
          if (!(widget instanceof Annotations.SignatureWidgetAnnotation)) {
            return {
              "background-color": "lightblue",
              "border": "1px solid darkblue",
              "color": "darkblue"
            }
          }
        }

        Annotations.WidgetAnnotation.getCustomStyles = customStyles

        const hidePageLoader = this.hidePageLoader

        this.documentViewer.addEventListener("documentLoaded", () => {
          if (this.hasSaveBtnTarget) {
            const doc = this.documentViewer.getDocument()

            this.saveBtnTarget.addEventListener("click", () => {
              this.saveBtnTarget.classList.add("disabled")
              this.allowFormSubmission()

              const xfdfUrl = this.data.get("xfdfUrl")
              this.saveXfdfString(this.annotationManager, doc, this.documentId, xfdfUrl).then(() => {
                this.showPageLoader()
                this.formTarget.submit()
              })
            })
          }

          this.documentViewer.getAnnotationsLoadedPromise().then(() => {
            this.getFilledPdfFields().then(() => {
              this.populateFilledFields()
              this.setupFieldListeners()
              this.setupAnnotationListeners()
              hidePageLoader()
            })
          })
        })
      })
  }

  populateFilledFields() {
    this.existingFields.forEach(field => {
      const fieldToUpdate = this.fieldManager.getField(field.name)
      if (fieldToUpdate && field.value != null && field.value !== "") {
        fieldToUpdate.setValue(field.value)
      } else if (field.name === "annotations") {
        this.annotationManager.importAnnotations(field.value)
      }
    })
  }

  getFilledPdfFields() {
    const formUrl = this.data.get("formUrl")
    const _this = this

    return get(formUrl, {
      contentType: "application/json",
      responseKind: "json"
    })
      .then(response => response.text)
      .then(json => JSON.parse(json))
      .then(fieldsArray => _this.existingFields = fieldsArray)
  }

  setupFieldListeners() {
    const saveField = this.saveFillablePdfField
    const formUrl = this.data.get("formUrl")
    const form = this.formTarget

    this.annotationManager.addEventListener("fieldChanged", (field, value) => {
      saveField(formUrl, field.name, value)
      form.dataset.changed = "true"
    })
  }

  setupAnnotationListeners() {
    const annotationManager = this.annotationManager
    const saveField = this.saveFillablePdfField
    const formUrl = this.data.get("formUrl")

    const saveXfdf = this.saveXfdfString
    const xfdfUrl = this.data.get("xfdfUrl")
    const doc = this.documentViewer.getDocument()
    const documentId = this.data.get("documentId")

    this.annotationManager.addEventListener("annotationChanged", (annotations, action, options) => {
      annotationManager.exportAnnotations({ fields: false, widgets: false, links: false }).then(xfdfString => {
        saveField(formUrl, "annotations", xfdfString)
        saveXfdf(annotationManager, doc, documentId, xfdfUrl)
      })
    })
  }

  saveXfdfString(annotationManager, doc, documentId, xfdfUrl) {
    return annotationManager.exportAnnotations().then(xfdfString => {
      return doc.getFileData({ xfdfString: xfdfString, flatten: true }).then(data => {
        const arr = new Uint8Array(data)
        const pdfFile = new File([arr], `${documentId}.pdf`, { type: "application/pdf" })

        const url = "/rails/active_storage/direct_uploads"
        const upload = new DirectUpload(pdfFile, url)

        return new Promise((resolve, reject) => {
          upload.create((error, blob) => {
            if (error) {
              reject(`ActiveStorage Error: ${error}`)
              alert(`Error Exporting Completed PDF\n${error}`)
            } else {
              return patch(xfdfUrl, {
                body: JSON.stringify({ fillable_pdf: { xfdf: xfdfString, filled_pdf: blob.signed_id } }),
                contentType: "application/json",
                responseKind: "json"
              })
                .then(() => {
                  resolve("Done saving PDF via ActiveStorage direct_upload")
                })
                .catch(error => {
                  alert(`Error Saving PDF Annotations\n${error}`)
                })
            }
          })
        })
      })
    })
  }

  async saveFillablePdfField(formUrl, fieldName, fieldValue) {
    // const encodedFieldName = encodeURIComponent(fieldName).replace(/\./g, "%2E")
    const response = await patch(`${formUrl}/update_by_name`, {
      body: JSON.stringify({ fillable_pdf_field: { name: fieldName, value: fieldValue } }),
      contentType: "application/json",
      responseKind: "json"
    })

    if (!response.ok) {
      const json = await response.json
      alert(`Error Saving PDF Field\n${json.message}`)
    }
  }


  leavingPage(event) {
    if (this.isPdfChanged()) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE
        return event.returnValue
      }
    }
  }

  allowFormSubmission() {
    this.formTarget.dataset.changed = "false"
  }

  isPdfChanged() {
    return this.formTarget.dataset.changed == "true"
  }

  showPageLoader() {
    document.getElementById("loading-indicator").style.display = ""
  }

  hidePageLoader() {
    document.getElementById("loading-indicator").style.display = "none"
  }
}
