import { Controller } from "@hotwired/stimulus"
import { get, patch } from "@rails/request.js"
import { DirectUpload } from "@rails/activestorage"

// this must be dynamically loaded or it conflicts with FullCalendar for some reason
// import "./pspdfkit/pspdfkit.js"

const LEAVING_PAGE_MESSAGE = "You have unsaved changes to this form, if you leave now those changes will be lost. You must press the 'Save PDF & Continue' button in order to save your changes. Are you sure you want to leave this page?"

const LICENSE_KEY = document.querySelector("meta[name=\"nutrient-license-key\"]").getAttribute("content")

export default class extends Controller {
  static targets = ["editor", "completionForm"]
  static values = {
    pdfTemplateUrl: String,
    fillablePdfId: String,
    fillablePdfFormUrl: String,
    fillablePdfFieldsFormUrl: String,
    directUploadUrl: String,
    author: String,
  }

  disconnect() {
    this.editorTarget.replaceChildren()
  }

  async connect() {
    // We need to inform PSPDFKit where to look for its library assets,
    // i.e.the location of the `pspdfkit-lib` directory.
    const baseUrl = `${window.location.protocol}//${window.location.host}/pspdfkit/`

    try {
      // Import the module from the public directory
      const PSPDFKit = await import("/pspdfkit/pspdfkit.js")
    } catch (error) {
      console.error("Failed to dynamically load PSPDFKit:", error)
    }

    const disabledToolbarItems = ["print", "export-pdf", "sign", "pan", "multi-annotations-selection",
      "text-highlighter", "pager", "sidebar-thumbnails", "sidebar-document-outline", "sidebar-annotations", "sidebar-bookmarks", "sidebar-signatures", "sidebar-layers", "search", "link", "document-editor", "document-crop"]
    const toolbarItems = PSPDFKit.defaultToolbarItems.filter(item => !disabledToolbarItems.includes(item.type))

    // FIT_TO_VIEWPORT is the default zoom mode, but we want to fit to width
    const initialViewState = new PSPDFKit.ViewState({ zoom: PSPDFKit.ZoomMode.FIT_TO_WIDTH })

    this.getInstantJSON()
      .then(instantJSON => {
        PSPDFKit.load({
          baseUrl,
          licenseKey: LICENSE_KEY,
          container: this.editorTarget,
          theme: this.editorTheme(),
          instantJSON: instantJSON,
          document: this.pdfTemplateUrlValue,
          setAnnotationCreatorName: this.getAuthorValue,
          toolbarItems: toolbarItems,
          initialViewState: initialViewState
        })
          .then(instance => {
            // Make the instance available to the entire controller
            this.editorInstance = instance

            this.registerFormFieldListners()
            this.registerAnnotationListeners()

          })
          .catch(error => {
            alert(`Error loading Smart Form Editor\n${error.message}`)
          })
      })
      .catch(error => {
        alert(`Error prepopulating Smart Form\n${error.message}`)
      })
  }

  registerFormFieldListners() {
    this.editorInstance.addEventListener("formFieldValues.update", updatedFormFieldValues => {
      const formFieldValues = updatedFormFieldValues.toJS()

      formFieldValues.forEach(formField => {
        this.savePdfFieldValue(formField.name, formField.value)
      })
    })
  }

  editorTheme() {
    const theme = document.querySelector("html").dataset.bsTheme
    return (theme == "dark") ? PSPDFKit.Theme.DARK : PSPDFKit.Theme.LIGHT
  }

  registerAnnotationListeners() {
    const events = ["annotations.create", "annotations.update", "annotations.delete"]

    events.forEach(event => {
      this.editorInstance.addEventListener(event, (_annotation) => {
        this.exportInstantJSON()
      })
    })
  }

  async exportInstantJSON() {
    try {
      await this.editorInstance.save()
      const instantJSON = await this.editorInstance.exportInstantJSON()

      await this.saveInstantJSON(instantJSON)
    } catch (error) {
      console.error("Error during exportInstantJSON:", error)
      throw error // Ensure the error propagates to the caller
    }
  }

  async saveInstantJSON(instantJSON) {
    this.showPageLoader()

    const response = await patch(this.fillablePdfFormUrlValue, {
      body: JSON.stringify({ fillable_pdf: { metadata: { instantJSON: instantJSON } } }),
      contentType: "application/json",
      responseKind: "json"
    })

    this.hidePageLoader()
    this.completionFormTarget.dataset.changed = "false"

    if (!response.ok) {
      const json = await response.json
      alert(`Error Saving InstantJSON\n${json.message}`)
    }

    return response
  }

  async getInstantJSON() {
    const response = await get(this.fillablePdfFormUrlValue, {
      contentType: "application/json",
      responseKind: "json"
    })

    const json = await response.json

    if (response.ok) {
      const instantJSON = json.instantJSON
      return instantJSON
    } else {
      alert(`Error Fetching InstantJSON \n${json.message}`)
    }
  }

  async savePdfFieldValue(fieldName, fieldValue) {
    const response = await patch(this.fillablePdfFieldsFormUrlValue, {
      body: JSON.stringify({ fillable_pdf_field: { name: fieldName, value: fieldValue } }),
      contentType: "application/json",
      responseKind: "json"
    })

    if (!response.ok) {
      const json = await response.json
      alert(`Error Saving PDF Field\n${json.message}`)
    } else {
      this.completionFormTarget.dataset.changed = "true"
    }
  }

  submit(event) {
    const completionForm = this.completionFormTarget
    const submitButton = event.target

    event.preventDefault()

    submitButton.disabled = true
    submitButton.classList.add("disabled")

    this.showPageLoader()

    this.exportInstantJSON()
      .then(() => {
        this.exportPdf()
          .then(() => {
            completionForm.requestSubmit()
          })
          .catch(error => {
            alert(`Error submitting Smart form\n${error.message}`)
          })
      })
  }

  async exportPdf() {
    try {
      // Export the PDF from the editor instance
      const pdf = await this.editorInstance.exportPDF({ flatten: true })
      const fileName = `smart-form-${this.fillablePdfIdValue}-${Date.now()}.pdf`
      const blob = new File([pdf], fileName, { type: "application/pdf" })

      // Await the upload completion
      const upload = new DirectUpload(blob, this.directUploadUrlValue)
      const blobResponse = await this.uploadFileToActiveStorage(upload)

      // Send the blob's signed ID to the server
      await patch(this.fillablePdfFormUrlValue, {
        body: JSON.stringify({ fillable_pdf: { filled_pdf: blobResponse.signed_id } }),
        contentType: "application/json",
        responseKind: "json",
      })

    } catch (error) {
      console.error("Error during PDF export and upload:", error)
      alert(`Error exporting completed Smart Form PDF\n${error}`)
    }
  }

  uploadFileToActiveStorage(upload) {
    return new Promise((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) {
          reject(`ActiveStorage Error: ${error}`)
        } else {
          resolve(blob)
        }
      })
    })
  }

  leavingPage(event) {
    const pdfHasChanged = this.completionFormTarget.dataset.changed == "true"

    if (pdfHasChanged) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.preventDefault()
        // this doesnt seem to work, as the browsers seem to have a default message
        // that they present when users try to leave the page, it also does not seem
        // to catch the forward and back buttons but does for refresh and close tab
        event.returnValue = LEAVING_PAGE_MESSAGE
      }
    }
  }

  showPageLoader() {
    document.getElementById("loading-indicator").style.display = ""
  }

  hidePageLoader() {
    document.getElementById("loading-indicator").style.display = "none"
  }
}

