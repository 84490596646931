import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["map"]
  static values = {
    apiKey: String, // Add your Google Maps API Key as a value
    lat: Number,    // Latitude for the map's initial position
    long: Number,    // Longitude for the map's initial position
    title: String,  // Title for the marker
    content: String,  // Content for the marker
    markerUrl: String, // A URL that returns a JSON array of locations to plot as markers on the map
    noMarkersMessage: { type: String, default: "No valid markers found to plot on this map." }, // Message to display if no markers are found
    zoom: { type: Number, default: 14 } // Default zoom level
  }

  connect() {
    if (typeof google === "undefined") {
      this.loadGoogleMapsAPI().then(() => {
        this.initializeMap()
      }).catch((error) => {
        console.error("Google Maps failed to load:", error)
      })
    } else {
      this.initializeMap() // If already loaded
    }
  }

  // Load Google Maps API dynamically
  loadGoogleMapsAPI() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKeyValue}&callback=initMap`
      script.async = true
      script.defer = true

      // Resolve the promise when Google Maps API is loaded
      window.initMap = () => resolve()
      script.onerror = reject

      document.head.appendChild(script)
    })
  }

  // Initialize the Google Map
  initializeMap() {
    if (this.hasMarkerUrlValue) {
      this.map = new window.google.maps.Map(this.mapTarget)
      let successfulMarkers = 0 // Counter for successfully plotted markers


      this.getMarkerData().then(markers => {
        const bounds = new window.google.maps.LatLngBounds()

        markers.forEach(marker => {
          try {
            this.plotMarker(marker.latitude, marker.longitude, marker.title, marker.content)
            bounds.extend({ lat: marker.latitude, lng: marker.longitude }) // Add each marker’s location to bounds
            successfulMarkers++
          } catch (error) {
            console.error(`Error plotting marker for record id ${marker.id}: ${error.message}`)
          }
        })

        if (successfulMarkers === 0) {
          alert(this.noMarkersMessageValue)
        }

        if (markers.length > 0) {
          this.map.fitBounds(bounds) // Adjust the map to fit all markers
        }
      }).catch(error => {
        console.error("Error fetching Google Maps Marker Data:", error)
      })
    } else {
      const mapOptions = {
        center: { lat: this.latValue, lng: this.longValue },
        zoom: this.zoomValue
      }

      this.map = new window.google.maps.Map(this.mapTarget, mapOptions)

      this.plotSingleMarker()
    }
  }

  async getMarkerData() {
    const response = await get(this.markerUrlValue, {
      contentType: "application/json",
      responseKind: "json"
    })

    const json = await response.json

    if (response.ok) {
      return json
    } else {
      alert(`Error Fetching Google Maps Marker JSON \n${json.message}`)
    }
  }

  plotSingleMarker() {
    this.plotMarker(this.latValue, this.longValue, this.titleValue, this.contentValue)
  }

  plotMarker(lat, lng, title, content) {
    const position = { lat: lat, lng: lng }
    console.log(position)
    const marker = new window.google.maps.Marker({
      position: position,
      map: this.map,
      title: title
    })

    const infoWindow = new window.google.maps.InfoWindow({ content: content })

    window.google.maps.event.addListener(marker, "click", () => { infoWindow.open(this.map, marker) })
  }
}
